<template>
  <div>
    <div class="row" :class="$style.customRow">
      <div class="col-md-1 offset-md-4">
        <span title="Start Date">
          <i class="fa fa-calendar"></i>
        </span>
      </div>
      <div class="col-md-3">
        <datetime v-model="startDate" type='date' placeholder='Anfangsdatum (Erforderlich)' :min-datetime='startDateMin' :max-datetime='startDateMax'></datetime>
      </div>
      <div class="col-md-1" title="Wenn Sie dieses Datum angeben, haben Beauftragte keinen Zugriff auf die Aufgabe und sehen sie vor diesem Datum nicht auf ihren Listen.">
        <span>
          <i class="fa fa-question-circle"></i>
        </span>
      </div>
    </div>
    <div class="row" :class="$style.customRow">
      <div class="col-md-1 offset-md-4">
        <span title="Enddatum">
          <i class="fa fa-calendar"></i>
        </span>
      </div>
      <div class="col-md-3">
        <datetime v-model="dueDate" type='date' placeholder='Enddatum (Erforderlich)' :min-datetime='dueDateMin'></datetime>
      </div>
      <div class="col-md-1" title="Wenn Sie dieses Datum angeben, sehen Beauftragte es als Datum, vor dem sie die Aufgabe abschließen und einreichen müssen.">
        <span>
          <i class="fa fa-question-circle"></i>
        </span>
      </div>
    </div>
    <!-- <div class="row" :class="$style.customRow">
      <div class="col-md-1 offset-md-4">
        <span>
          <i class="fa fa-check-circle"></i>
        </span>
      </div>
      <div class="col-md-3">
        <input type="checkbox" v-model="isBlockAfterDueDate" id="cbBlock">
        <label for="cbBlock">Block After Due Date</label>
      </div>
      <div class="col-md-1" title="If you check this option then assignees will not be able to access the assignment after due date.">
        <span>
          <i class="fa fa-question-circle"></i>
        </span>
      </div>
    </div>
    <div class="row" :class="$style.customRow">
      <div class="col-md-1 offset-md-4">
        <span>
          <i class="fa fa-check-circle"></i>
        </span>
      </div>
      <div class="col-md-3">
        <input type="checkbox" v-model="isSingleAccess" id="cbSingleAccess">
        <label for="cbSingleAccess">Single Access</label>
      </div>
      <div class="col-md-1" title="If you check this option then assignee will be able to access the assignment only once and will have to complete it during one session. This option is helpful for test and exams.">
        <span>
          <i class="fa fa-question-circle"></i>
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import tippy from 'tippy.js';
// import tools from '@/tools';

export default {
  name: 'fourthstep',
  data() {
    return {
      startDate: null,
      dueDate: null,
      isBlockAfterDueDate: false,
      isSingleAccess: false,
      startDateMin: null,
      startDateMax: null,
      dueDateMin: null,
    };
  },
  props: ['sDate', 'dDate', 'block', 'single'],
  beforeMount() {
    const now = new Date();
    this.startDateMin = now.toISOString();
    this.dueDateMin = now.toISOString();
    this.startDate = this.sDate;
    this.dueDate = this.dDate;
    this.isBlockAfterDueDate = this.block;
    this.isSingleAccess = this.single;
  },
  beforeDestroy() {
    const data = {};
    data.assignment_start_date = this.startDate;
    // data.assignment_end_date = this.dueDate;
    const dueArr = this.dueDate.split("T");
    data.assignment_end_date = dueArr[0] + 'T23:58:59.000Z';
    // console.log('destroy', data);
    data.block_after_due_date = this.isBlockAfterDueDate;
    data.single_access = this.isSingleAccess;
    data.status = true;
    this.$parent.setAssignmentData(data);
  },
  updated() {
    const data = {};
    data.assignment_start_date = this.startDate;
    // data.assignment_end_date = this.dueDate;
    const dueArr = this.dueDate.split("T");
    data.assignment_end_date = dueArr[0] + 'T23:58:59.000Z';
    // console.log(this.dueDate);
    // console.log('update', data);

    data.block_after_due_date = this.isBlockAfterDueDate;
    data.single_access = this.isSingleAccess;
    data.status = true;
    this.$parent.setAssignmentData(data);

    if (this.dueDate) {
      this.startDateMax = this.dueDate;
    }

    if (this.startDate) {
      this.dueDateMin = this.startDate;
    }
  },
  mounted() {
    tippy('[title]', {
      placement: 'right',
    });
  },
};
</script>

<style src='./style.less' lang='less' module/>
