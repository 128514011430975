<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <!-- <div class="row">
        <div class="col-md-4" v-if="categories.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedCategory" v-html="categoriesOptString" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-4" v-if="themes.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedTheme" v-html="themesOptString" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div v-if="categories.length > 0 && themes.length > 0" class="col-md-4">
          <div :class="$style.divDdl">
            <Search :class="$style.divSearch"></Search>
          </div>
        </div>
        <div v-else class="col-md-4 offset-md-4">
            <Search></Search>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-3">
          <button class="btn mt-3" style="background-color:#c0272d; color:white" @click="goBack()"><i class="fas fa-arrow-circle-left"></i> Zurück</button>
        </div>
        <div class="col-md-6">
          <h1 class="title menuTitle" style="font-weight: normal;" :class='$style.menuTitle'>{{title}}</h1>
        </div>
        <div class="col-md-3">
        </div>
      </div>
        <hr>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div class="row" :class="$style.columns" v-else-if="scormData.length > 0">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" :class="$style.column" v-for="(item,i) in this.scormData" :key="i">
          <Card
          :coverImageHeight='coverImgHeight'
          contentType="scorm"
          :id='item.id'
          :scormTitle='item.name'
          :imgpath="item.image"
          :scormCode='item.scorm_code'
          :scormDesc='item.scorm_details1'
          :isHistory='item.isHistory'
          :packageId='parseInt($route.params.id, 10)'>
          </Card>
        </div>
        <div class="col-md-12" >
          <lmsPagination
            v-if="this.pagination && this.scormData.length > 0"
            :data="this.pagination"
            @goToPage="goToPage">
          </lmsPagination>
        </div>
      </div>
      <div v-else>
        <p :class="$style.noContent">Kein inhalt.</p>
      </div>

    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import lmsPagination from '@/components/pagination';
  import Card from '@/components/Card2';
  // import Search from '@/components/Search';
  import services from '@/services/content';

  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'lessons',
    data() {
      return {
        scormData: [],
        linkData: [],
        title: '',
        pagination: null,
        ready: false,
        themes: [],
        categories: [],
        themesOptString: '',
        categoriesOptString: '',
        selectedTheme: 0,
        selectedCategory: 0,
        searchText: '',
        coverImgHeight: null,
        searchData: [],
        routerCollectionId: 0,
        historyScorms: [],
      };
    },
    components: {
      HeaderCustom,
      Card,
      // Search,
      InnerLoading,
      lmsPagination,
    },
    computed: {
      ...mapState(['scorms']),
    },
    async beforeMount() {
      /*eslint-disable*/
      this.routerCollectionId = this.$route.params.collectionId;
      const collectionId = parseInt(this.$route.params.id, 10);
      if (collectionId === 46) {
        this.coverImgHeight = '240px';
      }
      this.title = this.$store.state.collections.data.find(x => x.id === collectionId).name;
      this.setData();
      const getHistory = await services.getHistoryScorms();
      this.historyScorms = getHistory.scorm_ids;
      for (const item of this.scormData) {
        // console.log(item.id);
        if(this.inArray(item.id, this.historyScorms)){
          item.isHistory = true;
          // console.log(item.id + "true");
        }else{
          item.isHistory = false;
          // console.log(item.id + "false");
        }
      }
    },
    methods: {
      ...mapActions(['getPackages']),
      search(text) {
        if (!text) {
          this.searchText = '';
          this.setPackages();
        } else {
          this.searchText = text.toLocaleLowerCase('tr-TR').trim();
          this.setPackages();
        }
      },
      setData() {
        const querystring = this.$route.query;
        if (querystring.page) {
          this.getData(querystring.page);
        } else {
          this.goToPage(1);
        }
      },
      setPackages() {
        /*eslint-disable*/
        const collectionId = parseInt(this.$route.params.id, 10);
        this.scormData = this.searchData
                                    .filter(x => this.searchText ? x.name.toLocaleLowerCase('tr-TR').includes(this.searchText) :
                                    true)
                                    .filter(x => Number(this.selectedTheme) !== 0 ? (x.scorm_theme !== null &&
                                                      x.scorm_theme.id === Number(this.selectedTheme)) :
                                                      true)
                                    .filter(x => Number(this.selectedCategory) !== 0 ? (x.scorm_category !== null &&
                                                      x.scorm_category.id === Number(this.selectedCategory)) :
                                                      true);
        this.fillDropdowns();
      },
      fillDropdowns() {
        //THEME DDL
        this.themes = _.uniqBy(this.scormData, 'scorm_theme.name').filter(x => x.scorm_theme && x.scorm_theme.id !== null).map(x => x.scorm_theme);
        this.themesOptString = '';
        this.themesOptString += '<option value="0">All Themes</option>';
        _.orderBy(this.themes, 'name').forEach((theme) => {
          const opt = `<option value='${theme.id}'>${theme.name}</option>`;
          this.themesOptString += opt;
        });
        //CATEGORY DDL
        this.categories = _.uniqBy(this.scormData, 'scorm_category.name')
                                  .filter(x => x.scorm_category && x.scorm_category.id !== null)
                                  .map(x => x.scorm_category);
        this.categoriesOptString = '';
        this.categoriesOptString += '<option value="0">All Categories</option>';
        _.orderBy(this.categories, 'name').forEach((category) => {
          const opt = `<option value='${category.id}'>${category.name}</option>`;
          this.categoriesOptString += opt;
        });
      },
      inArray(needle, haystack) {
        let length = haystack.length;
        for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
        }
        return false;
      },
      goToPage(page) {
        const collectionId = parseInt(this.$route.params.id, 10);
        this.$router.push({
          name: 'scorms',
          params: {
            id: collectionId,
          },
          query: { page },
        });
      },
      async getData(page) {
        this.ready = false;
        const collectionId = parseInt(this.$route.params.id, 10);
        // console.log('collect_id', collectionId);
        const res = await services.getScorms(collectionId, page);
        this.pagination = res.pagination;
        this.scormData = res.data;
        this.searchData = res.data;
        if(this.pagination.last_page > 1){

            for (let i = this.pagination.current_page; i <= this.pagination.last_page; i++) {
              const result = await services.getScorms(collectionId, i);
              result.data.forEach(element => {
                this.searchData.push(element);
              });
            }
        }
        this.fillDropdowns();
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      },
      goBack() {
        this.$router.push({
          name: 'collections',
          params: {
            id: this.routerCollectionId,
            searchList: 'collections',
          },
        });
      },
    },
    watch: {
      selectedTheme() {
        this.setPackages();
      },
      selectedCategory() {
        this.setPackages();
      },
      $route() {
        this.setData();
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>
