module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#c0272d',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: '2vOovGWrDy84Exs7L0LL9Ad3mgO9YD6z886C2ltSORdYdRMI',
};
