<template>
  <div :class="$style.divSearch">
    <span :class="$style.searchIcon">
      <i class="fa fa-search fa-1x" aria-hidden="true" :class="$style.icon"></i>
    </span>
    <span v-if="this.txtSearch" :class="$style.removeIcon" @click="cleanText">
      <i class="fa fa-remove fa-1x" aria-hidden="true" :class="$style.icon"></i>
    </span>
    <input type="text" @keyup="search" class="form-control" :class="$style.txtSearch" placeholder="Suchen" v-model="txtSearch">
  </div>
</template>

<script>
export default {
  data() {
    return {
      txtSearch: '',
    };
  },
  methods: {
    search() {
      /*eslint-disable*/
      this.$parent.search(this.txtSearch);
    },
    cleanText() {
      this.txtSearch = '';
      this.$parent.search(this.txtSearch);
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

