<template>
  <div>
    <div id="content" ref="content">
      <nav role="navigation">
          <div class="menu">
              <div class="sizing-btns" @click="fit()">{{isFit ? 'originalgröße' : 'Pass an Fenster'}}</div>
              <div v-if="this.userDetails.title !== 'demo'" class="save-btn" @click="saveAndExit()">Speichern und Schließen</div>
              <div v-else class="save-btn" @click="saveAndExit()">Exit</div>
          </div>
      </nav>
      <iframe
      v-if="isLoaded"
      id="iframe"
      ref="iframe"
      allowfullscreen="allowfullscreen"
      :style="[styleFrame, {opacity: isReady ? 1 : 0}]"
      :src="`https://content.ueslms.com/scorms/${$route.params.scormCode}/index_new.html`"></iframe>
      <div v-if="!isReady" class="loading">
        <div class="icon"/>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import serviceLesson from '@/services/content';

export default {
  name: 'Scorm',
  // props: ['scormData', 'scormType'],
  props: ['packageId', 'scormTitle', 'scormDesc', 'scormCode', 'timestamp', 'packageType'],
  data() {
    return {
      isLoaded: false,
      isReady: false,
      state: null,
      isFit: true,
      styleFrame: {
        left: 0,
        transform: 0,
      },
      scormWidth: 0,
      scormHeight: 0,
    };
  },
  // computed: {
  //   ...mapGetters([
  //     't',
  //     'details',
  //   ]),
  // },
  computed: {
    ...mapState(['userDetails']),
  },
  methods: {
    // ...mapMutations([
    //   'setScore',
    // ]),
    // ...mapActions([
    //   'setAssignments',
    //   'setReaders',
    //   'refreshToken',
    // ]),
    sentState() {
      if (!this.state) return;
      const iframeWindow = this.$refs.iframe.contentWindow || this.$refs.iframe.contentDocument;
      const message = `SET_STATE;|${this.state}`;
      iframeWindow.postMessage(message, '*');
      console.log('SENT STATE (in app)');
    },
    getState() {
      const data = {
        user_id: Number(this.userDetails.user_id),
        scorm_id: Number(this.$route.params.id),
        stateable_type: this.$route.params.packageType ? this.$route.params.packageType : 'package',
        stateable_id: Number(this.$route.params.packageId),
      };
      serviceLesson.loadState(data).then((res) => {
        // console.log('res', res);
        if (res[0]) this.state = res[0].state;
        this.isLoaded = true;
      }).catch((err) => {
        console.log('ERR', err);
      });
    },
    saveStateScore(state, score) {
      const req = {
        user_id: Number(this.userDetails.user_id),
        scorm_id: Number(this.$route.params.id),
        stateable_type: this.$route.params.packageType ? this.$route.params.packageType : 'package',
        stateable_id: Number(this.$route.params.packageId),
        state,
        score: `{"score": ${JSON.stringify(score)}}`,
      };
      if (this.userDetails.title !== 'demo') {
        serviceLesson.saveStateScore(req).then((res) => {
          // const scoreData = {
          //   type: `${this.scormType}s`,
          //   package: this.scormData.content_id,
          //   scorm: this.scormData.scorm_id,
          //   score: score.scaledScore * 100,
          // };
          // this.setScore(scoreData);
          // console.log('res', res);
          this.$router.go(-1);
        }).catch((err) => {
          console.log('ERR', err);
        });
      } else {
        this.$router.go(-1);
      }
    },
    fit() {
      this.isFit = !this.isFit;
      this.onResize();
    },
    // onResize() {
    //   const contentWidth = this.$refs.content.offsetWidth;
    //   const contentHeight = this.$refs.content.offsetHeight;
    //   let sizeContent = contentWidth;
    //   let sizeScorm = this.scormWidth;
    //   let scormWidth = this.scormWidth;
    //   let isWide = contentWidth / this.scormWidth > contentHeight / this.scormHeight;
    //   if (isWide) {
    //     if (this.isFit) {
    //       sizeContent = contentHeight;
    //       sizeScorm = this.scormHeight;
    //     } else {
    //       sizeScorm = this.scormWidth + 18;
    //       scormWidth += 18; 
    //     }
    //   }
    //   const rate = sizeContent / sizeScorm;
    //   this.styleFrame.left = `${(contentWidth - (scormWidth * rate)) / 2}px`;
    //   this.styleFrame.transform = `scale(${rate})`;
    // },
    // fillResize(width, height) {
    //   if (!this.$refs.iframe) return;
    //   this.scormWidth = width + 20;
    //   this.scormHeight = height + 20;
    //   this.$refs.iframe.style.width = `${this.scormWidth}px`;
    //   this.$refs.iframe.style.height = `${this.scormHeight}px`;
    //   this.onResize();
    //   this.isReady = true;
    // },
    onResize() {
      // console.log('--', this.$refs.content.clientWidth);
      // console.log(' --', this.$refs.content.offsetWidth);
      const contentWidth = this.$refs.content.offsetWidth;
      const contentHeight = this.$refs.content.offsetHeight - 50;
      let sizeContent = contentWidth;
      let sizeScorm = this.scormWidth;
      let scormWidth = this.scormWidth;
      let isWide = contentWidth / this.scormWidth > contentHeight / this.scormHeight;
      if (this.isFit) {
        if (isWide) {
          sizeContent = contentHeight;
          sizeScorm = this.scormHeight;
        } else {
          sizeContent = contentWidth;
          sizeScorm = this.scormWidth;
        }
        this.styleFrame.margin = '0';
        const rate = sizeContent / sizeScorm;
        this.styleFrame.left = `${(contentWidth - (scormWidth * rate)) / 2}px`;
        this.styleFrame.transform = `scale(${rate})`;
      } else {
          // sizeScorm = this.scormWidth + 18;
          // scormWidth += 18;
        this.styleFrame.left = '0';
        this.styleFrame.right = '0';
        this.styleFrame.margin = 'auto';
        this.styleFrame.transform = `scale(1)`;
        this.$refs.content.style.overflowX = 'auto';
      }
    },
    fillResize(width, height) {
      if (!this.$refs.iframe) return;
      this.scormWidth = width + 40;
      this.scormHeight = height + 40;
      this.$refs.iframe.style.width = `${this.scormWidth - 20}px`;
      this.$refs.iframe.style.height = `${this.scormHeight - 20}px`;
      this.onResize();
      this.isReady = true;
    },
    saveAndExit() {
      this.isReady = false;
      const iframeWindow = this.$refs.iframe.contentWindow || this.$refs.iframe.contentDocument;
      iframeWindow.postMessage('SAVEANDCLOSE', '*');
    },
    onMessageReceived(event) {
      if (event.data.indexOf('LOADED') === 0) {
        this.isSuccess = true;
        console.log('RECEIVED LOADED (in app)');
      }else if (event.data.indexOf('PLAYER_LOADED') === 0) {
        this.sentState();
        console.log('RECEIVED PLAYER LOADED (in app)');
      } else if (event.data.indexOf('GET_STATE_SCORE;|') === 0) {
        const splitData = event.data.split(';|');
        this.saveStateScore(splitData[1], JSON.parse(splitData[2]));
        console.log('RECEIVED STATE AND SCORE (in app)');
      } else if (event.data.indexOf('RESIZE:') === 0) {
        const sizes = event.data.split('RESIZE:');
        const width = sizes[1].split(';')[0];
        const height = sizes[1].split(';')[1];
        this.fillResize(parseFloat(width), parseFloat(height));
      }
    },
  },
  beforeMount() {
    if (!this.scormCode) {
      this.$router.push('/');
    } else {
      this.getState();
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('message', this.onMessageReceived, false);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('message', this.onMessageReceived);
  },
};
</script>

<style src='./style.less' lang='less' scoped></style>
